import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useFirebase, useFirestore } from "react-redux-firebase";
import urls, { login } from "urls";
import { COMPANY_CONSTANTS } from "../../../../constants/CompanyConstants";
import { Link, useNavigate, useParams } from "react-router-dom";

const backgroundStyle = {
  backgroundImage: "url(/img/others/img-17.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const AcceptInvite = () => {
  const { t } = useTranslation();
  const { encodedLink } = useParams();
  const navigate = useNavigate();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [validLink, setValidLink] = useState(true);
  const [invitedEmail, setInvitedEmail] = useState("");
  const [invitedUserId, setInvitedUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invitationFound, setInvitationFound] = useState(false);
  const invitedUsers = useSelector((state) => state.firestoreReducer.ordered.invitedUsers);
  const inviteLink = encodedLink;

  const decodeLink = (link) => {
    try {
      return atob(link);
    } catch (err) {
      return "";
    }
  };

  useEffect(() => {
    if (!invitationFound) {
      let decodedLink = decodeLink(inviteLink);
      let parts = decodedLink.split("/");
      let id = parts[0];
      let email = parts[1];
      setInvitedUserId(id);
      setInvitedEmail(email);
      if (!!invitedUsers) {
        let validLink = _.findIndex(invitedUsers, ["id", id]) >= 0;
        if (!validLink) {
          setValidLink(false);
        } else setInvitationFound(true);
      }
    }
  }, [invitedUsers]);

  const onSubmit = () => {
    let formEmailMatchesInvite = invitedEmail === email;
    let role = _.find(invitedUsers, ["id", invitedUserId]).role;
    if (formEmailMatchesInvite && !!role) {
      setLoading(true);
      firebase
        .createUser(
          {
            // Authentication object
            email: email,
            password: password,
          },
          // Profile object (/users/:id)
          {
            email: email,
            role: role,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
        )
        .then(() => {
          let user = firebase.auth().currentUser;
          const actionCodeSettings = {
            url: urls.base + urls.login,
          };
          // Send verification email
          user
            .sendEmailVerification(actionCodeSettings)
            .then(function () {
              message.success(t("notifications.invite_link_sent"), 8);
              // Remove invitedUser from firestore
              firestore
                .collection("invitedUsers")
                .doc(invitedUserId)
                .delete()
                .then(() => {
                  firebase.logout();
                  navigate(login);
                })
                .catch((error) => console.log("Error deleting invited user: ", error));
            })
            .catch(function (error) {
              setLoading(false);
              console.error(error);
            });
        });
    } else {
      message.warning(t("notifications.invite_invalid_email"));
    }
  };

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        {validLink ? (
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={9}>
              <Card>
                <div className="my-2">
                  <div className="text-center">
                    <img className="img-fluid w-75" src="/img/logo.webp" alt="" />
                    <h3 className="mt-3 font-weight-bold">
                      {t("accept_invite")}
                      {COMPANY_CONSTANTS.WEBSITE_NAME}
                    </h3>
                    <p className="mb-4">{t("form.enter_email")}</p>
                  </div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <Form form={form} layout="vertical" name="forgot-password" onFinish={onSubmit}>
                        <Form.Item
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          rules={[
                            {
                              required: true,
                              message: t("form.enter_email"),
                            },
                            {
                              type: "email",
                              message: t("form.enter_valid_email"),
                            },
                          ]}
                        >
                          <Input placeholder={t("email")} prefix={<MailOutlined className="text-primary" />} />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          rules={[
                            {
                              required: true,
                              message: t("form.enter_password"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("password")}
                            type="password"
                            prefix={<LockOutlined className="text-primary" />}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button loading={loading} type="primary" htmlType="submit" block>
                            {loading ? t("registering") : t("register")}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={9}>
              <Card>
                <div className="text-center">
                  <h3 className="mt-3 font-weight-bold">{t("invalid_link_title")}</h3>
                  <p className="mb-4">{t("invalid_link_description", { company: COMPANY_CONSTANTS.COMPANY_NAME })}</p>
                  <Link to={login}>{t("to_login")}</Link>
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default AcceptInvite;
