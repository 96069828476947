import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Upload,
} from "antd";
import Flex from "../../../components/shared-components/Flex";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { formatNameToURL } from "../../../functions/formatters";
import EllipsisDropdown from "../../../components/shared-components/EllipsisDropdown";
import { FIRESTORE_BLOGPOSTS_TABLE } from "../../../constants/FirestoreConstant";
import ImgCrop from "antd-img-crop";
import firebase from "../../../configs/FirebaseConfig";
import styled from "styled-components";
import moment from "moment";
import { uniq } from "lodash";
import { beforeUpload } from "../../../functions/images";
import { uploadImageToStorage } from "../../../functions/firebase/storage";
import MarkdownEditor from "../../../components/util-components/MarkdownEditor";

const ADD = "ADD";
const EDIT = "EDIT";
const imagesPath = "blogPosts";

const Blog = () => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  useFirestoreConnect([{ collection: FIRESTORE_BLOGPOSTS_TABLE }]);
  const blogPosts = useSelector((state) => state.firestoreReducer.ordered.blogPosts);
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const [addBlogPostForm] = Form.useForm();
  const [editBlogPostForm] = Form.useForm();
  const [addBlogPostModalVisible, setAddBlogPostModalVisible] = useState(false);
  const [editBlogPostModalVisible, setEditBlogPostModalVisible] = useState(false);
  const [blogPostCategories, setBlogPostCategories] = useState([]);
  const [blogPostToEdit, setBlogPostToEdit] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedWidth, setSelectedWidth] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editImage, setEditImage] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [inputMarkdown, setInputMarkdown] = useState("");
  const categoryInputRef = useRef(null);

  {
    /* Get all unique categories from all blog posts */
  }
  useEffect(() => {
    if (!!blogPosts) {
      const categories = [];
      blogPosts.forEach((post) => {
        if (!!post.category) categories.push(post.category);
      });
      const uniqueCategories = uniq(categories);
      setBlogPostCategories(uniqueCategories);
    }
  }, [blogPosts]);

  const openEditModal = (post) => {
    setBlogPostToEdit(post);
    setEditImage(false);
    editBlogPostForm.setFieldsValue(post);
    setEditBlogPostModalVisible(true);
    setIsActive(post.active);
    setInputMarkdown(post.content);
  };

  const deleteBlogPost = (post) => {
    const storage = firebase.storage();
    const imageRef = storage.refFromURL(post.image);
    imageRef
      .delete()
      .then(() => {
        firestore
          .collection(FIRESTORE_BLOGPOSTS_TABLE)
          .doc(post.id)
          .delete()
          .then((r) => {
            message.success(t("notifications.blog_post_removed"));
          });
      })
      .catch((err) => console.log(err));
  };

  const closeAddModal = () => {
    setAddBlogPostModalVisible(false);
    setSelectedWidth(null);
    setUploadedImage(null);
    setLoading(false);
    setInputMarkdown("");
    addBlogPostForm.resetFields();
  };

  const closeEditModal = () => {
    setEditBlogPostModalVisible(false);
    setBlogPostToEdit(null);
    setLoading(false);
    setUploadedImage(null);
    setInputMarkdown("");
    editBlogPostForm.resetFields();
  };

  const handleUpdateBlogPost = () => {
    editBlogPostForm.validateFields().then((values) => {
      setLoading(true);
      values.content = inputMarkdown;
      values.description = values.description || values.metaDescription;
      values.imageAlt = !!values.imageAlt ? values.imageAlt : null;
      delete values.image;
      let object = setBlogPostLoggingProperties(values, EDIT);

      const updateBlogPostInFirestore = (object) => {
        firestore
          .collection(FIRESTORE_BLOGPOSTS_TABLE)
          .doc(blogPostToEdit.id)
          .update(object)
          .then(() => {
            closeEditModal();
            message.success(t("notifications.blog_post_updated"), 2);
          })
          .catch((err) => {
            message.error(err.message);
            setLoading(false);
          });
      };

      if (uploadedImage) {
        const storage = firebase.storage();
        const oldImageRef = storage.refFromURL(blogPostToEdit.image);
        oldImageRef
          .delete()
          .then(() => {
            const imageName = uploadedImage.uid + "-" + uploadedImage.name;
            const imageRef = firebase.storage().ref(imagesPath + "/" + imageName);
            return imageRef.put(uploadedImage.originFileObj);
          })
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            object.image = url;
            updateBlogPostInFirestore(object);
          })
          .catch((error) => {
            message.error(error.message);
            setLoading(false);
          });
      } else {
        updateBlogPostInFirestore(object);
      }
    });
  };

  const setBlogPostLoggingProperties = (post, mode) => {
    if (mode === ADD)
      return { ...post, createdAt: moment().unix(), createdBy: !!post.createdBy ? post.createdBy : email };
    else if (mode === EDIT)
      return {
        ...post,
        updatedAt: moment().unix(),
        updatedBy: !!post.createdBy ? post.createdBy : email,
      };
  };

  const handleAddBlogPost = () => {
    addBlogPostForm.validateFields().then((values) => {
      setLoading(true);
      values.content = inputMarkdown;
      values.description = values.description || values.metaDescription;
      values.imageAlt = !!values.imageAlt ? values.imageAlt : null;
      values.published = true;
      delete values.image;
      let object = setBlogPostLoggingProperties(values, ADD);
      const imageName = uploadedImage.uid + "-" + uploadedImage.name;
      const images = [{ name: imageName, file: uploadedImage.originFileObj }];
      Promise.all(
        // Array of "Promises"
        images.map((item) => uploadImageToStorage(item, imagesPath)),
      )
        .then((urls) => {
          urls.forEach((item) => {
            object.image = item.url;
          });
          firestore
            .collection(FIRESTORE_BLOGPOSTS_TABLE)
            .add(object)
            .then((response) => {
              closeAddModal();
              message.success(t("notifications.blog_post_saved"), 2);
            })
            .catch((err) => {
              message.error(err);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error.message);
          setLoading(true);
        });
    });
  };

  const onChangeImage = (file) => {
    const status = file.status;
    if (status === "done") setUploadedImage(file);
    if (status === "removed") setUploadedImage(null);
  };

  const addCategory = (e) => {
    e.preventDefault();
    setBlogPostCategories([...blogPostCategories, newCategory]);
    setNewCategory("");
    setTimeout(() => {
      categoryInputRef.current?.focus();
    }, 0);
  };

  /**
   * Image uploader expects to do a request, so we mock one
   */
  const dummyRequest = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const formatBlogUrl = (productName) => {
    const formattedName = formatNameToURL(productName);
    addBlogPostForm.setFieldsValue({ url: formattedName });
  };

  return (
    <Card>
      <Flex alignItems="between" justifyContent="between" mobileFlex={false}>
        <Flex alignItems="end" className="mb-1" mobileFlex={false}>
          <div>
            <Button onClick={() => setAddBlogPostModalVisible(true)} type="primary" icon={<PlusCircleOutlined />} block>
              {t("add_blog")}
            </Button>
          </div>
        </Flex>
      </Flex>
      <div className="table-responsive">
        <Table
          columns={[
            {
              title: t("title"),
              dataIndex: "title",
              sorter: (a, b) => a.title.localeCompare(b.title),
              sortDirections: ["ascend", "descend"],
            },
            {
              title: t("author"),
              dataIndex: "createdBy",
            },
            {
              title: t("created_at"),
              dataIndex: "createdAt",
              render: (date) => (
                <span>{`${moment.unix(date).format("DD MMMM YYYY")} ${t(
                  "at",
                )} ${moment.unix(date).format("HH:mm")}`}</span>
              ),
              sorter: (a, b) => a.createdAt - b.createdAt,
            },
            {
              title: t("updated_at"),
              dataIndex: "updatedAt",
              render: (date) => (
                <span>
                  {date
                    ? `${moment.unix(date).format("DD MMMM YYYY")} ${t("at")} ${moment.unix(date).format("HH:mm")}`
                    : ""}
                </span>
              ),
              sorter: (a, b) => a.updatedAt - b.updatedAt,
            },
            // {
            //   title: t("status"),
            //   dataIndex: "published",
            //   render: (published) => (
            //     <Tag color={published ? "green" : "orange"}>{published ? t("published") : t("concept")}</Tag>
            //   ),
            // },
            {
              title: t("url"),
              dataIndex: "url",
              render: (url) => <span>{!!url ? "/" + url : "-"}</span>,
            },
            {
              title: t("category"),
              dataIndex: "category",
            },
            {
              title: t("image"),
              dataIndex: "image",
              width: 100,
              render: (image) => <img src={image} alt={""} style={{ maxWidth: 100, maxHeight: 80 }} />,
            },
            {
              title: "",
              dataIndex: "actions",
              render: (_, row) => (
                <div className="text-right">
                  <EllipsisDropdown
                    menu={
                      <Menu>
                        <Menu.Item>
                          <Flex alignItems="center">
                            <EyeOutlined />
                            <a
                              href={"https://halalvlees-online.nl/blog/" + row.url}
                              target={"_blank"}
                              style={{ marginLeft: 4, color: "inherit" }}
                            >
                              {t("view")}
                            </a>
                          </Flex>
                        </Menu.Item>
                        <Menu.Item onClick={() => openEditModal(row)}>
                          <Flex alignItems="center">
                            <EditOutlined />
                            <span style={{ marginLeft: 4 }}>{t("edit")}</span>
                          </Flex>
                        </Menu.Item>
                        <Menu.Item>
                          <Flex alignItems="center">
                            <DeleteOutlined />
                            <Popconfirm
                              placement="bottom"
                              title={t("delete_blog_post")}
                              onConfirm={() => deleteBlogPost(row)}
                              okText={t("yes")}
                              cancelText={t("no")}
                            >
                              <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                            </Popconfirm>
                          </Flex>
                        </Menu.Item>
                      </Menu>
                    }
                  />
                </div>
              ),
            },
          ]}
          dataSource={blogPosts}
          rowKey="id"
          scroll={{ x: true }}
        />
      </div>
      <Modal
        title={t("add_blog")}
        open={addBlogPostModalVisible}
        onCancel={() => closeAddModal()}
        onOk={handleAddBlogPost}
        okText={t("save")}
        cancelText={t("close")}
        width={1200}
        confirmLoading={loading}
      >
        <Form layout="vertical" form={addBlogPostForm} name="advanced_search" className="ant-advanced-search-form">
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name={"image"}
                label={t("image")}
                rules={[
                  () => ({
                    validator(rule, value) {
                      return !!uploadedImage ? Promise.resolve() : Promise.reject(t("form.upload_image"));
                    },
                  }),
                ]}
              >
                {!uploadedImage ? (
                  <ImgCrop
                    modalWidth={1000}
                    minZoom={0}
                    modalTitle={t("crop_image")}
                    modalOk={t("add")}
                    modalCancel={t("close")}
                    aspect={16 / 9}
                    cropperProps={{
                      restrictPosition: false,
                    }}
                  >
                    <Upload
                      beforeUpload={beforeUpload}
                      onChange={(e) => onChangeImage(e.file)}
                      customRequest={dummyRequest}
                      maxCount={1}
                      multiple={false}
                      showUploadList={false}
                    >
                      <Button icon={<UploadOutlined />}>{t("upload")}</Button>
                    </Upload>
                  </ImgCrop>
                ) : (
                  <ImageFileWrapper>
                    <p>{uploadedImage?.name}</p>
                    <DeleteOutlined onClick={() => setUploadedImage(null)} />
                  </ImageFileWrapper>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name={"imageAlt"} label={t("alt")}>
                <Input placeholder={t("alt_text")} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={14}>
              <Form.Item
                name="title"
                label={t("title")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("title")} onChange={(e) => formatBlogUrl(e.target.value)} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item
                name={"url"}
                label={t("url")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input addonBefore={process.env.REACT_APP_BASEURL + "/blog/"} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name={"category"}
                label={t("category")}
                rules={[
                  {
                    required: true,
                    message: t("form.pick_value"),
                  },
                ]}
              >
                <Select
                  placeholder={t("form.choose_category")}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder={t("form.add_category")}
                          ref={categoryInputRef}
                          value={newCategory}
                          onChange={(e) => setNewCategory(e.target.value)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addCategory}>
                          {t("add")}
                        </Button>
                      </Space>
                    </>
                  )}
                  options={blogPostCategories.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="createdBy"
                label={t("author")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("author")} />
              </Form.Item>
            </Col>
            <Divider />
            <Col lg={12}>
              <Form.Item
                name="metaDescription"
                label={t("meta_description")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input.TextArea placeholder={t("meta_description")} rows={3} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item name="description" label={t("description_blog")}>
                <Input.TextArea placeholder={t("description_blog")} rows={3} />
              </Form.Item>
            </Col>
            <Divider />
            <Col xs={24}>
              <Form.Item
                name={"content"}
                label={t("content")}
                rules={[
                  () => ({
                    validator() {
                      if (!!inputMarkdown) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t("form.enter_value"));
                    },
                  }),
                ]}
              >
                <MarkdownEditor setMarkdown={setInputMarkdown} markdown={inputMarkdown} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {editBlogPostModalVisible && (
        <Modal
          title={t("edit_recipe")}
          open={editBlogPostModalVisible}
          onCancel={() => closeEditModal()}
          onOk={handleUpdateBlogPost}
          okText={t("save")}
          cancelText={t("close")}
          width={1000}
          confirmLoading={loading}
        >
          <Form layout="vertical" form={editBlogPostForm} name="advanced_search" className="ant-advanced-search-form">
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={"image"}
                  label={t("image")}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        return !!uploadedImage || (!!blogPostToEdit?.image && !editImage)
                          ? Promise.resolve()
                          : Promise.reject(t("form.upload_image"));
                      },
                    }),
                  ]}
                >
                  {!uploadedImage && (!blogPostToEdit?.image || editImage) ? (
                    <ImgCrop
                      modalWidth={1000}
                      minZoom={0}
                      modalTitle={t("crop_image")}
                      modalOk={t("add")}
                      modalCancel={t("close")}
                      aspect={16 / 9}
                      cropperProps={{
                        restrictPosition: false,
                      }}
                    >
                      <Upload
                        beforeUpload={beforeUpload}
                        onChange={(e) => onChangeImage(e.file, "nl")}
                        customRequest={dummyRequest}
                        maxCount={1}
                        multiple={false}
                        showUploadList={false}
                      >
                        <Button icon={<UploadOutlined />}>{t("upload")}</Button>
                      </Upload>
                    </ImgCrop>
                  ) : (
                    <ImageFileWrapper>
                      {(!!blogPostToEdit?.image || uploadedImage) && (
                        <img
                          src={uploadedImage ? uploadedImage : blogPostToEdit?.image}
                          alt={""}
                          style={{ maxWidth: 100, maxHeight: 80 }}
                        />
                      )}
                      <p>{uploadedImage?.name}</p>
                      <DeleteOutlined onClick={() => setEditImage(true)} />
                    </ImageFileWrapper>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name={"imageAlt"} label={t("alt")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={14}>
                <Form.Item
                  name="title"
                  label={t("title")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("title")} onChange={(e) => formatBlogUrl(e.target.value)} />
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item
                  name={"url"}
                  label={t("url")}
                  tooltip={{ title: t("form.enter_value"), icon: <InfoCircleOutlined /> }}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input addonBefore={process.env.REACT_APP_BASEURL + "/recepten/"} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name={"category"}
                  label={t("category")}
                  rules={[
                    {
                      required: true,
                      message: t("form.pick_value"),
                    },
                  ]}
                >
                  <Select
                    placeholder={t("form.choose_category")}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder={t("form.add_category")}
                            ref={categoryInputRef}
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button type="text" icon={<PlusOutlined />} onClick={addCategory}>
                            {t("add")}
                          </Button>
                        </Space>
                      </>
                    )}
                    options={blogPostCategories.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="createdBy"
                  label={t("author")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("author")} />
                </Form.Item>
              </Col>
              <Divider />
              <Col lg={12}>
                <Form.Item
                  name="metaDescription"
                  label={t("meta_description")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input.TextArea placeholder={t("meta_description")} rows={3} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="description" label={t("description_blog")}>
                  <Input.TextArea placeholder={t("description_blog")} rows={3} />
                </Form.Item>
              </Col>
              <Divider />
              <Col xs={24}>
                <Form.Item
                  name={"content"}
                  label={t("content")}
                  rules={[
                    () => ({
                      validator() {
                        if (!!inputMarkdown) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("form.enter_value"));
                      },
                    }),
                  ]}
                >
                  <MarkdownEditor setMarkdown={setInputMarkdown} markdown={inputMarkdown} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </Card>
  );
};

const ImageFileWrapper = styled.div`
  display: flex;
  transition: all 0.3s ease;
  padding: 0.2rem 0.3rem;
  align-items: center;

  p {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  span {
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export default Blog;
